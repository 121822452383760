import React, {lazy, Suspense, useContext} from 'react';
import '../../css/dashboard.css';
import '../reporting.css';
import './MenuItems.css';
import ResidentReport from '../../pages/ResidentReport/Resident_Report';
import { BrowserRouter, Route} from "react-router-dom";
import ArqDashboard from '../../pages/Assist/Dashboard';
import NodeTest from '../../nodetesting/nodetest';
import ChartSuite from '../../ChartSuite/ChartSuite';
import MainMenuItems from './MainMenuItems';
import MainNavBar from './MainNavBar';
import { MainPageLayout, MainFrame, ContentFrame } from '../Navigation';
import { decrypt } from '../../encrypt/encryptionHandler';
import AuthContext from '../../AuthContextProvider';
import {firebaseAuth} from '../../firebase/provider/AuthProvider';
import MultiPanelDashboardPage from '../MultiPanelDashboard/MultiPanelDashboardPage';
import CallCalendarPage from '../CallCalendar/CallCalendarPage';
import UserAdmin from '../../pages/UserAdmin/UserAdmin';
import LocationPage from '../../pages/Location/Location';
import NDashboard from '../../pages/NDashboard/NDashboard';
import ErrorLogs from '../../pages/ErrorLogs/ErrorLogs';
import { Modal } from 'react-bootstrap';
import { API_POST } from '../../data/API_POST';
import SiteCalls from '../../v2/Pages/onboarding/siteCalls';
import MonitoringReport from '../../v2/Pages/onboarding/MonitoringReport';
import Sense from '../../v2/Pages/Sense/SenseMain';
import ComponentLibrary from '../../v2/components/ComponentLibrary';
import NourishMenu from '../../v2/integrations/nourish/nourish';
import IntegrationsMenu from '../../v2/integrations/integrations';
import packageJson from '../../../package.json';

{/** Lazy loading routes */}
const Care = lazy(() => import('../care/Care'));
const SupportScreen = lazy(() => import('../support/support'));
const AIDATeam = lazy(() => import('../../teams/TeamMembers'));
const CallDataFilter = lazy(() => import('../../components/CallDataFilter/CallDataFilter'));
const CallDataFilterOrig = lazy(() => import('../../components/CallDataFilterOrig/CallDataFilter'));
const ReportsCharts = lazy(() => import('../../components/CallDataFilter/ReportsCharts'));
const ReportsChartsP = lazy(() => import('../CallDataFilterP/ReportsCharts'));
const Dashboard = lazy(() => import('../../pages/NewDashboard/Dashboard'));
const GetPCSCareMainMenu = lazy(() => import('../../pages/PCSCare/PSCCare'));
const Maintenance = lazy(() => import('../../pages/Maintenance/Maintenance'));
const Onboarding = lazy(() => import('../../pages/Onboarding/Onboarding'));
const UserSiteManagement = lazy(()=>import ('../../pages/Admin/UserSiteManagement'));
const Trends = lazy(()=>import ('../../components/Trends/Trends'));


function MainMenu({USER}) {

  const ctx = useContext(AuthContext);
  
  if (USER===undefined) {
    const tmp = localStorage.getItem("user_object");
    USER = JSON.parse(JSON.parse(decrypt(JSON.parse(tmp))));
  }
  
  function hasPermissionGranted(permissions, permissionToHave){
    if (permissions==null) return false;
    if (typeof(permissions)!=="object") return false;
    let rtnVal = false;

    Object.keys(permissions).map(p=>{
        if ((permissions[p].permission===permissionToHave)&&(permissions[p].granted===1)) rtnVal = true;
    });
    return rtnVal;
  } 

  function showUpdateMenu(){
    const access = hasPermissionGranted(ctx.permissions, "v2_access");
    const ask = hasPermissionGranted(ctx.permissions, "v2_ask");
    if ((access==false)&&(ask===true)) return true;
    return false;
  }

  const {handleSignout} = useContext(firebaseAuth);

  if ((ctx.sites===null)||(ctx.sites.length===0)) return <LoginNotFound handleSignout={handleSignout} />
  
  if (showUpdateMenu()===true) return <TryNewSite />;

    return(
    <>

      <MainPageLayout>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
          <Route exact path="/ndash" component={NDashboard} />
          <Route path="/"><MainNavBar /></Route>
          <MainFrame>
            <Route exact path="/">
              <ContentFrame>
                <MainMenuItems user={ctx.user} site={ctx.sites} version={packageJson.version} permissions={ctx.permissions} />
              </ContentFrame>
            </Route>
            <Route exact path="/repgraphs">  
              <ReportsChartsP 
                perspective="Call"
                //chartsList={[{ name: 'Call Type Breakdown' },{ name: 'Average Response Times' }]}
                chartsList={[{ name: 'Average Response Times' }, { name: 'Call Type Breakdown' }]} 
                filterViewSelections={['Homes', 'Zones', 'Call Types', 'Call Points', 'Carers']}  
              />
            </Route>
            {/*<Route exact path="/otsukare" component={Trends} />*/}
            <Route exact path="/Trends" component={Trends} />
            <Route exact path="/monitoringreport" component={MonitoringReport} />
            
            {/*<Route exact path="/zzzpaneltesting" component={PanelTesting} />*/}
            <Route exact path="/onboarding" component={Onboarding } />
            <Route exact path={"/errorlogs"} component={ErrorLogs} />
            <Route exact path={"/idashboard"} component={Dashboard} />
            <Route exact path={"/report"} component={CallDataFilter} />
            <Route exact path={"/call_data"} component={CallDataFilterOrig} />
            <Route exact path={"/call_datan"} component={CallDataFilter} />
            <Route exact path="/rep_res_call_data" component={ResidentReport} />
            <Route exact path="/chartsuite" component={ChartSuite} />
            <Route exact path="/UserSiteManagement" component={UserSiteManagement} />
            <Route exact path={["/about", "/support"]} component={SupportScreen} />
            <Route exact path={["/teams","/team"]}><AIDATeam USER={USER} /></Route>
            <Route exact path={["/assist","/dashboard"]}><ArqDashboard permissions={ctx.permissions} USER={USER} /></Route>
            <Route exact path="/node" component={NodeTest} />
            <Route exact path="/maintenance"><Maintenance siteValue={USER.site[0].code} siteDisplayName={USER.site[0].name} /></Route>
            <Route exact path="/chartsuite" component={ChartSuite} />
            <Route exact path="/db" component={SiteCalls} />
            <Route exact path={["/sense"]}><Sense USER={USER}></Sense></Route>            
            <Route exact path={["/aqadmin"]}><MultiPanelDashboardPage USER={USER} /></Route>
            <Route exact path={["/callcalendar"]}><CallCalendarPage USER={USER} /></Route>
            <Route exact path={"/useradmin"} component={UserAdmin} />
            <Route exact path={"/move"} component={LocationPage} />
            <Route exact path={"/integrations"}>
              <NourishMenu SEC={ctx}></NourishMenu>
            </Route>
            <Route exact path={"/componenttest"} component={LocationPage} />
            {/* Care routes */}
            <Route exact path="/care" component={Care} />
            <Route path="/care/pcs" component={GetPCSCareMainMenu} />
            <Route exact path={["/care/dashboard"]} component={ArqDashboard} />
            <Route exact path="/care/reports">
              <ReportsCharts  
                perspective="Care" 
                chartsList={[{name:'Care Report', value:''}]} 
                filterViewSelections={['Homes']}
              />
            </Route>
            </MainFrame>
          </Suspense>
        </BrowserRouter>
      </MainPageLayout>
    </>
  );

}

export default MainMenu;

export const LoginNotFound=({handleSignout})=>{
  function closeModalHandler(){}
  return(
    <Modal show={true} onHide={closeModalHandler} size="lg">
    <div className='aqBackgroundSolid'>
      <p>You are not configured for a site.
      <button
      onClick={()=>{
        handleSignout();
      }}
    >Sign Out</button>
    </p>
    </div>
  </Modal>
  );
}

const TryNewSite=()=>{
  function closeModalHandler(){}

  function onData(data){
    window.location.reload();
  }

  function onError(err){

  }

  function optionNo(){
    const postData = {
      userID:"1",
 	    permissionName: "v2_ask",
      permissionValue: false
    };
    API_POST("/configs/setpermission", postData, onData, onError);
  }

  function optionYes(){
    const postData = {
      userID:"1",
 	    permissionName: "v2_access",
      permissionValue: true
    };
    API_POST("/configs/setpermission", postData, onData, onError);
  }

  return(
    <Modal show={true} onHide={closeModalHandler} size="lg">
      <div>
        <h1>Arquella Dashboard</h1>
        <p>Your site is now compatible with the latest Arquella Dashboard.</p>
        <p>Would you like to trial this dashboard now?</p>
        <p>You can revert back to the old dashboard at any point from you user settings.</p>
        <button onClick={()=>{optionYes();}}>Yes please</button> &nbsp; &nbsp; &nbsp;<button onClick={()=>{optionNo();}}>Not right now</button>
      </div>
    </Modal>
  );
}