import React, {Suspense, useContext} from 'react';
import { BrowserRouter, Route} from "react-router-dom";
import AuthContext from '../AuthContextProvider';
import DashRoute from './dashboard/route';
import './index.css';

const MainPage = (props) => {

    const ctx = useContext(AuthContext);

    console.log("MainPage", ctx);

    return <DashRoute />;

    return (
      <>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
                <Route path="/"><DashRoute /></Route>
{/*
          <Route exact path="/ndash" component={NDashboard} />
            <Route path="/"><MainNavBar /></Route>
            <Route exact path={["/about", "/support"]} component={SupportScreen} />

          /ndash
          /
          /repgraphs
          /Trends
          /zzzpaneltesting
          /onboarding
          /errorlogs
          /idashboard
          /report
          /call_data
          /call_datan
          /rep_res_call_data
          /chartsuite
          /UserSiteManagement
          /about
          /support
          /teams
          /team
          /assist
          /dashboard
          /node
          /maintenance
          /chartsuite
          /multidash
          /callcalendar
          /useradmin
          /location
          /componenttest
          /care
          /care/pcs
          /care/dashboard
          /care/reports

*/}
          </Suspense>
        </BrowserRouter>
    
      </>
  )
};

export default MainPage;

