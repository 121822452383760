import React, { useContext, useState, useEffect } from 'react';
import './splashcollect.css';
import IMG_AIDA_LOGO from '../../images/icons/aida_logo.png';
import axios from 'axios';
import MainMenu, {LoginNotFound} from './MainMenu';
import { decryptAndParse } from '../../encrypt/encryptionHandler';
import AuthContext, { getPermission } from '../../AuthContextProvider';
import getLocalSetting from '../get_local_setting';
import MainPage from '../../v2/main';
import { checkPanelDB } from '../../firebase/firebaseindex';
import {firebaseAuth} from '../../firebase/provider/AuthProvider';
import { Log } from 'victory';


const SplashCollect = () => {

  //localStorage.setItem("dbVal", 1);
  const ctx = useContext(AuthContext);
  const {handleSignout} = useContext(firebaseAuth);

  const [userObject, setUserObject] = useState(null);
  const [requiredFields, setRequiredFields] = useState({
    fields: ["sites"]
  });

  const [menu, setMenu] = useState(false);

  useEffect(() => {

    updateDBValues("/user/sites", "sites");
    updateDBValues("/user/carers", "carers");
    setPermissions("/user/permissions");
    checkValidServiceWorker();
    getUserObject();

  }, []);

  function getUserObject() {
    let sData = {
      "user": localStorage.getItem("userEmail"),
      "userkey": localStorage.getItem("userEmail")
    };

    axios.post('/user/user_object', sData)
      .then(response => {
        const r = response.data;
        localStorage.setItem("user_object", JSON.stringify(r));
        const decryptObj = JSON.parse(decryptAndParse(r));
        ctx.user = decryptObj.user;
        ctx.sites = decryptObj.site ? decryptObj.site : [];
        ctx.permissions = decryptObj.permissions ? decryptObj.permissions : [];
        ctx.sitePanels = decryptObj.site_panels ? decryptObj.site_panels : [];

        setUserObject(decryptObj);
        getDBVals(decryptObj.site);

      })
      .catch(e => { console.log("Error", e); });
  }

  function getDBVals(sites) {
    checkPanelDB()
      .then(d => {
        const vs = Object.values(d);
        const match = vs.filter(v => v.siteRef == sites[0].code);
        if (match.length > 0) {

          switch (match[0].db) {

            case "https://arquella-cc76e-39485.europe-west1.firebasedatabase.app":
              localStorage.setItem("dbVal", 7);
              break;
            case "https://arquella-cc76e-5c7be.europe-west1.firebasedatabase.app":
              localStorage.setItem("dbVal", 6);
              break;
            case "https://arquella-cc76e-d2306.europe-west1.firebasedatabase.app":
              localStorage.setItem("dbVal", 5);
              break;

            case "https://arquella-cc76e-b7352.europe-west1.firebasedatabase.app":
              localStorage.setItem("dbVal", 4);
              break;
            case "https://arquella-cc76e-afe91.europe-west1.firebasedatabase.app":
              localStorage.setItem("dbVal", 2);
              break;
            case "https://arquella-cc76e.firebaseio.com":
              localStorage.setItem("dbVal", 1);
              break;
            default:
              localStorage.setItem("dbVal", 1);
              break;
          }
        }
        else {
          localStorage.setItem("dbVal", 1);
        }

      })
      .catch(e => console.log(e));
  }

  function getDBValsFunction(sites, onSuccess, onFail) {
    console.log({ sites });

    
    checkPanelDB()
      .then(d => {
        console.log("dbValllsfunc", d);
        const vs = Object.values(d);
        console.log({ vs });
        const match = vs.filter(v => v.siteRef == sites[0].code);
        console.log({ match });
        if (match.length > 0) {
          localStorage.setItem("dbPath", match[0].db);
          if (match.db === "https://arquella-cc76e-afe91.europe-west1.firebasedatabase.app") {
            localStorage.setItem("dbVal", 2);
          }

          if (match.db === "https://arquella-cc76e.firebaseio.com") {
            localStorage.setItem("dbVal", 1);
          }

        }
        else {
          localStorage.setItem("dbPath", "https://arquella-cc76e.firebaseio.com");
        }
        onSuccess();
      })
      .catch(e => {
        console.log(e);
        onFail();
      });
  }


  function updateCompleteFields(updatedField) {
    let tmpArr = requiredFields.fields;
    let fieldIndex = tmpArr.indexOf(updatedField);
    if (fieldIndex > -1) { tmpArr.splice(fieldIndex, 1); }       //remove from required array if updated

    setRequiredFields({ fields: tmpArr });
  }

  function setPermissions(path) {
    let sData = {
      "user": localStorage.getItem("userEmail"),
      "userkey": localStorage.getItem("userEmail")
    };
    axios.post(path, sData)
      .then(response => {
        let r = decryptAndParse(response.data);
        if ((r == "") || (r == null)) { r = "[]" }
        if (Array.isArray(r)) {
          r.map(pm => {
            localStorage.setItem(pm.pm_name, true)
          });
        }
        updateCompleteFields("permissions");
      }).catch(response => {

      });
  }



  function updateDBValues(path, updateItem) {
    localStorage.setItem("userKey", localStorage.getItem("userEmail"));
    let sData = {
      "user": localStorage.getItem("userEmail"),
      "userkey": localStorage.getItem("userEmail")
    };
    axios.post(path, sData
    )
      .then(response => {
        let r = JSON.stringify(response.data);
        if ((r == "") || (r == null)) { r = "[]" }
        localStorage.setItem(updateItem, r);
        updateCompleteFields(updateItem);
      });
  }


  if (userObject) {
    console.log("usdus", userObject);
    if (userObject.site === null)  {

      return (
        <>
          <div style={logoStyle}><img className="splashFading" src={IMG_AIDA_LOGO} /></div>
          <br />
          <LoginNotFound handleSignout={handleSignout} />
          
        </>
      );

    } 
    if (menu === true) return <MainMenu USER={userObject} />;

    try { getDBValsFunction(userObject.site, () => setMenu(true), () => setMenu(false)); }
    catch (e) {
      console.log("dbVal error");
      console.log(e);
      console.log({ userObject });
    }

  }

  return (<div style={logoStyle}><img className="splashFading" src={IMG_AIDA_LOGO} /></div>);


}

const logoStyle = {
  margin: "auto",
  width: "100%",
  paddingTop: "10%",
};

function checkValidServiceWorker() {
  let workerEnabled = false;
  if (navigator.serviceWorker) { workerEnabled = true; }
  sendServiceWorkerStatus(workerEnabled);
}


function sendServiceWorkerStatus(status) {
  try {
    console.log("sendServiceWorkerStatus");
    let sData = {
      "user": localStorage.getItem("userEmail"),
      "userkey": localStorage.getItem("userEmail"),
      "workerservicestatus": status
    };
    axios.post("/user/workerservicestatus", sData)
      .then(response => { })
      .catch(response => {

      });
  } catch (e) { console.log(e); }
}


export default SplashCollect;

